//==========================================================================
// Abstrakts (ITCSS & BEM Method) Style coding
//==========================================================================
//======================================== CONFIGS
@import '_config/colours';
//======================================== UTILS
@import '_utils/breakpoints';
@import '_utils/background-cover';
@import '_utils/clearfix';
@import '_utils/hidden-visible';
@import '_utils/index';
//======================================== BASE
@import 'base/reset';
@import 'base/html-body';
@import 'base/typography';
@import 'base/icon';
@import 'base/inputs';
@import 'base/button';
//======================================== LAYOUT
@import 'layout/grid';
//Bootstrap
@import 'layout/navigation';
@import 'layout/structure';
@import 'layout/header';
@import 'layout/footer';
//======================================== MODULES
@import 'modules/hero';
@import 'modules/tile';
@import 'modules/models-list';
@import 'modules/models-hold';
@import 'modules/cta';
//======================================== VENDOR
@import '_vendor/slick';

.animated {
  animation-duration: 1s !important;
}

.td-n { text-decoration: none; }
strong { font-weight: bold; }
img { pointer-events: none !important; }
.tile--sl img { pointer-events: all !important; }

#cookie-message {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999;

  .wrapper--cookie {
    background-color: palette(green, base);
    padding: 20px;
  }

  p { color: #333; }

  .btn {
    margin-bottom: 20px;
    margin-right: 10px;
    &:last-of-type { margin-bottom: 0; }
  }

}

@include breakpoint(medium) {
  #cookie-message {
    .wrapper--cookie {
      padding: 20px;
    }

    p { margin-bottom: 0; }

    .cookie-button {
      text-align: right;
    }

    .btn {
      margin-right: 0;
    }
  }
}

//======================================== PRINT
@import '_utils/print';
