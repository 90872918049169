// ==========================================================================
// #HEADER
// ==========================================================================
#header {
  background-color: white;
  position: fixed;
  width: 100%;
  z-index: 9;
  padding: gutter(1);
  border-bottom: 1px solid palette(brand, compliment);

  .logo {
    max-width: 200px;
    position: relative;
    z-index: 7;
    .icon,
    svg {
      width: 100%;
    }
  }

  .mobile-menu {
    font-size: 2.5rem;
    position: absolute;
    z-index: 9;
    top: gutter(1);
    right: gutter(1);

    .menu-toggle {
      border: 0;
      padding: 0;
      font-weight: 300;
      font-size: $p-size;
      background: 0;
      outline: none;

      .close {
        display: none;
      }
    }
  }
}

@include breakpoint-max(medium) {
  #header.menu-active {
    .menu-toggle {
      .close { display: block; }
      .open { display: none; }
    }
  }
}

@include breakpoint(small) {
  #header {
    padding: gutter(3) gutter(1);

    .logo {
      position: relative;
      display: inline-block;
      max-width: 240px;
    }

    .mobile-menu {
      font-size: 3rem;
      top: gutter(3);
      right: gutter(1);
    }
  }
}

@include breakpoint(medium) {
  #header {
    position: relative;
    border-bottom: 0;
    .mobile-menu {
      display: none;
    }
  }
}