//=====================================
// #HEADINGS
//=====================================
$h1-size: 8rem;
$h2-size: 6rem;
$h3-size: 4rem;
$h4-size: 2.4rem;

$p-size: 2.2rem;
$p-size--small: 1.8rem;
$p-size--big: 2.6rem;

$heading-font: 'Playfair Display', serif;
$body-font: 'Source Sans Pro', sans-serif;

h1, .h1,
h2, .h2,
h3, .h3 {
  margin-bottom: 25px;
  line-height: 1.3;
  font-family: $heading-font;
}

h1, .h1 {
  font-size: 6rem;

  @include breakpoint(small) {
    font-size: $h1-size;
  }
}

h2, .h2 {
  font-size: 4rem;

  @include breakpoint(small) {
    font-size: $h2-size;
  } }

h3, .h3 { font-size: $h3-size; }

h4, .h4 { font-size: $h4-size; }

//=====================================
// #TEXT
//=====================================
p, .p {
  font-family: $body-font;
  font-size: $p-size;
  line-height: 1.5;
  margin-bottom: 20px;
  font-weight: 300;
}

.small > p,
.small > .p,
.small {
  font-size: $p-size--small;
}

.big > p,
.big > .p,
.big {
  font-size: $p-size--big;
}

a, .link--fake {
  cursor: pointer;
  color: palette(black, text);
  text-decoration: underline;
  font-weight: 400;
  transition: color .3s;

  &:hover {
    color: palette(brand, compliment);
  }

  &.link--black {
    color: palette(black, text);

    &:hover {
      color: palette(brand, compliment);

      span {
        fill: palette(brand, compliment);
      }
    }
  }
}

.link--icon  {
  span {
    margin-left: 10px;
  }
}

//=====================================
// #LISTS
//=====================================
ul, ol {
  margin-left: 20px;
  padding-top: 10px;
  padding-bottom: 25px;

  &.ul--row {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: 0;
      float: left;
    }
  }
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li {
  font-size: $p-size;
  line-height: 1.5;
  font-weight: 300;
  color: palette(black, text);
  padding-bottom: 15px;
}

//=====================================
// #COLOURS/LINKS
//=====================================
.color--white {
  color: white;
  > * {
    color: white;
  }
}

.color--black-text {
  color: palette(black, text);
  > * {
    color: palette(black, text);
  }
}

.color--brand {
  color: palette(brand, compliment);
  > * {
    color: palette(brand, compliment);
  }
}

//=====================================
// #BLOCKS
//=====================================

.pagination {
  text-align: center;

  a, span {
    padding: 0 2px;
    margin: 0 8px;
    color: black;
    font-weight: 300;

    @include breakpoint(medium) {
      margin: 0 12px;
    }
  }

  .icon {
    padding: 0;
    opacity: .5;
    transition: opacity .5s;

    &:hover {
      opacity: 1;
    }
  }

  .previous {
    margin-right: 10px;
  }
  .next {
    margin-left: 10px;
  }

  @include breakpoint(medium) {
    .previous {
      margin-right: 20px;
    }
    .next {
      margin-left: 20px;
    }
  }

  span {
    &.current {
      border-bottom: 2px solid palette(brand, compliment);
    }
  }
}

.intro_block {

  .link-push {
    a {
      text-decoration: none;
      display: table;
      border-bottom: 2px solid palette(brand, compliment);
    }
  }

  @include breakpoint(medium) {
    span.h2, h2 {
      margin-bottom: -6.5rem;
      display: block;
      position: relative;
      z-index: 2;
    }

    img {
      //border-right: 30px solid white;
    }
  }

  @include breakpoint(large) {
    .link-push {
      a {
        display: inline-block;
        margin-left: 15px;
      }
    }

    img {
      //border-right: 80px solid white;
    }
  }
}

.inline-block {
  margin: 15px 0 25px;

  iframe {
    width: 100%;
    height: 320px;
  }

  @include breakpoint(medium) {
    margin: 30px 0 40px;

    iframe {
      height: 450px;
    }
  }
}

.heading {
  font-family: $heading-font;
}
