// ==========================================================================
// #ICONS
// ==========================================================================
.icon {
  @include bg-cover();
  display: inline-block;
  vertical-align: middle;
  background-size: contain;
  > svg { display: block; }
  path, polygon, rect, circle { transition: fill .3s; }
}

// ==========================================================================
// #ICONS-COLORS
// ==========================================================================
.icon--white {
  path { fill: white; }
}

.icon--black {
  path, polygon { fill: black !important; }
}

// Colors
@each $color, $shades in $palettes {
  $colorName: if($color == grey, '-', '--#{$color}');
  @each $shade, $value in $shades {
    $shadeModifier: if($shade == base, '', '-#{$shade}');

    @if $shade == base {
      $shadeModifier: if($color == grey, '-#{$color}', '');
    } @else {
      $shadeModifier: '-#{$shade}';
    }

    .icon#{$colorName}#{$shadeModifier} {
      path, polygon, rect, circle {
        fill: palette($color, $shade);
      }
    }
  }
}

// ==========================================================================
// #ICONS-SIZE
// ==========================================================================

.icon--20 {
  &,
  & > svg {
    width: 20px;
    height: 20px;
  }
}

.icon--25 {
  &,
  & > svg {
    width: 25px;
    height: 25px;
  }
}

.icon--30 {
  &,
  & > svg {
    width: 30px;
    height: 30px;
  }
}

.icon--40 {
  &,
  & > svg {
    width: 40px;
    height: 40px;
  }
}

.icon--60 {
  &,
  & > svg {
    width: 60px;
    height: 60px;
  }
}

.icon--logo {
  &,
  & > svg {
    width: 240px;
    height: auto;
    path { fill: #1D1D1B; }
  }
}

.icon--logo-f {
  &,
  & > svg {
    width: 100px;
    height: auto;
    path { fill: #1D1D1B; }
  }
}

@include breakpoint(medium) {
  .icon--logo-f {
    &,
    & > svg { width: 45px; }
  }
}

.icon--shortlist-h {
  &,
  & > svg {
    width: 24px;
    height: 24px;
  }
}

.icon--shortlist {
  &,
  & > svg {
    overflow: visible;
    box-shadow: 0px -0px 10000px transparent;

    polygon {
      fill: transparent;

      &.star-back {
        stroke: palette(brand, compliment);
        stroke-width: 10px;
      }
      &.star-front { opacity: 0; fill: palette(brand, compliment); }
    }
  }

}

.shortlist--active .icon--shortlist {
  &,
  & > svg {
    polygon {
      &.star-back { fill: palette(brand, compliment); }
      &.star-front {
        animation: starFade .5s linear;
        animation-fill-mode: forwards;
        transform-origin: center;
      }
    }
  }
}

.shortlist--deactivated .icon--shortlist {
  &,
  & > svg {
    polygon {
      &.star-back { fill: transparent; }
      &.star-front {
        animation: starError .5s linear;
        animation-fill-mode: forwards;
        transform-origin: center;
      }
    }
  }
}

// ==========================================================================
// #ICONS-SOCIAL
// ==========================================================================
.icon--facebook, .icon--twitter, .icon--instagram, .icon--mail {
  svg > * { fill: palette(black, text) }
}

a:hover {
  .icon--facebook svg > * { fill: #3b5998; }
  .icon--twitter svg > * { fill: #00b6f1; }
  .icon--instagram svg > * { fill: #c32aa3 }
  .icon--youtube svg > * { fill: #D42428 }
  .icon--mail svg > * { fill: palette(brand, compliment); }
}

@keyframes starFade {
  0% { opacity: 1; }
  99% {
    opacity: 0;
    transform: scale(3);
  }
  100% { transform: scale(1); }
}

@keyframes starError {
  0% {
    opacity: 1;
    fill: palette(red, error);
  }
  99% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    fill: palette(brand, compliment);
    transform: scale(1);
  }
}