//=====================================
// #NAVIGATION
//=====================================
.menu-active nav.h-nav {
  opacity: 1;
  visibility: visible;
}

nav.h-nav a { text-decoration: none; }

@include breakpoint-max(medium) {
  nav.h-nav {
    transition: opacity .3s;
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: palette(brand, compliment);
    padding-top: 50px;

    ul {
      width: 100%;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      li {
        float: none;
        text-align: center;
        font-family: $heading-font;

        a {
          font-size: 4rem;
          color: #333;
          text-decoration: none;
        }

        .icon {
          display: none;
        }

        span.number {
          font-family: $body-font;
          display: inline-block;
          font-size: 2rem;
          line-height: 1;
          background-color: white;
          border-radius: 50%;
          padding: gutter(.5) 0;
          width: 30px;
          height: 30px;
          text-align: center;
        }
      }
    }
  }

  .hamburger {
    display: inline-block;
  }
}

@include breakpoint(medium) {
  nav.h-nav {
    position: relative;
    float: right;

    ul {
      margin-left: 0;
      position: relative;
      padding-top: 0;
    }

    li {
      margin-right: 40px;
      padding-bottom: 0;
      float: left;
      list-style: none;

      ul {
        position: absolute;
        top: 100%;
        margin-top: 10px;
        margin-left: 0;
        padding: 0;

        li {
          float: left;
          opacity: 0;
          margin-right: 20px;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }

      &.link--active > a {
        border-bottom: solid palette(brand, compliment) 2px;
      }

      .last-link {
        margin-right: 40px;
      }

      &.shortlist-link {
        position: fixed;
        right: gutter(1);
        background-color: black;
        padding: 0 15px 2px;
        transition: background-color .3s;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        span.number {
          color: white;
          display: block;
          text-align: center;
          font-weight: bold;
          transition: color .3s;
        }

        span.text { display: none; }

        &:before {
          content: "";
          background-color: black;
          position: absolute;
          left: 0;
          height: 200px;
          width: 100%;
          bottom: 100%;
          transition: background-color .3s;
        }

        &:hover {
          background-color: palette(brand, compliment);
          &:before { background-color: palette(brand, compliment); }
          .icon--brand-compliment path, .icon--brand-compliment polygon { fill: #333 }
          span.number { color: #333; }
        }
      }

      &:hover ul {
        z-index: 9;

        //li {
        //  animation: bumpLeft .4s ease-out;
        //  animation-fill-mode: forwards;
        //
        //  @for $i from 1 through 4 {
        //    &:nth-child(#{$i}) {
        //      $length: 1 + ($i + $i);
        //      animation-delay: $length * .1s;
        //    }
        //  }
        //}
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    a {
      font-size: 2rem;
      position: relative;
      overflow: hidden;
      display: block;
      color: black;
      font-family: "Playfair Display", serif;

      &:hover {
        color: palette(brand, compliment)
      }
    }
  }
}

//@keyframes bumpLeft {
//  0% {
//    opacity: 0;
//    transform: translateX(30px);
//  }
//  100% {
//    opacity: 1;
//    transform: translateX(0);
//  }
//}


