//=====================================
// #HERO
//=====================================
.hero {
  position: relative;
  //margin: 0 gutter(1);
}

.hero--img {
  padding: gutter(10) gutter(2);
  @include bg-cover();

  .hero_inner {
    position: relative;
    z-index: 2;
  }
}

.hero__tint {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
}

.hero__tint--black {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,ffffff+100&0.8+0,0+100 */
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#99000000', endColorstr='#00ffffff', GradientType=1); /* IE6-9 */
}

.hero__tint--white {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#99ffffff', endColorstr='#00ffffff', GradientType=1); /* IE6-9 */
}

.hero-split {
  width: 100%;
  padding: 0 gutter(1);
  height: calc(100vh - 95px);

  .hero__item {
    position: relative;
    background-color: yellow;
    min-height: 33%;
    overflow: hidden;
    margin-bottom: gutter(1);
    @include bg-cover();

    &:last-of-type {
      margin-bottom: 0;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.4);
    }
  }

  .hs__inner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: gutter(2);

    .hs__sub { display: none; }
    a {
      text-decoration: none;
      color: #333;
    }
  }
}

@include breakpoint(small) {
  .hero--img { padding: gutter(15) gutter(2); }
}

@include breakpoint(medium) {

  .hero--img {
    padding: gutter(20) gutter(4);

    h1 {
      font-size: 11rem;
    }
  }

  .hero-split {
    padding: 0;
    height: calc(100vh - 105px);
    display: flex;
    border-left: gutter(1)solid white;
    border-right: gutter(1) solid white;

    .hero__item {
      height: 100%;
      width: 33%;
      margin-bottom: 0;
      background-color: yellow;
      transition: all .8s ease-out;
      flex: 1;
      flex-basis: auto;
      border-left: 6px solid white;
      border-right: 6px solid white;

      &:first-of-type { border-left: 0; }
      &:last-of-type { border-right: 0; }

      &--i1 .hs__inner { padding-bottom: gutter(15); }
      &--i3 .hs__inner { padding-top: gutter(15); }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.1);
        transition: background  .3s;
      }

      &:hover {

        &:before { background-color: rgba(255, 255, 255, 0.4); }

        .hs__inner {
          padding-left: gutter(5);

          a {
            color: #333;

            &:hover {
              color: palette(brand, compliment);
            }
          }

          ul {
            @for $i from 1 through 4 {
              li:nth-child(#{$i}) a {
                $length: 1 + ($i);
                animation-delay: $length * .2s;
              }
            }

            a {
              animation: bumpUp .8s ease-out;
              animation-fill-mode: forwards;
            }
          }
        }
      }
    }

    .hs__inner {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding: gutter(2);
      transition: padding .8s;

      .hs__sub { display: block; }

      a {
        color: white;
        transition: color .3s;
      }

      ul {
        margin-left: 0;
        list-style: none;

        li { padding-bottom: 0; }

        a {
          display: block;
          white-space: nowrap;
          opacity: 0;
        }
      }
    }


    &.i2 {
      .hero__item {
        &--i2 { width: 66%; }
        &--i3 { width: 0; border: 0; opacity: 0; }
      }
    }

    &.i1 {
      .hero__item {
        &--i1 { width: 66%; }
        &--i3 { width: 0; border: 0; opacity: 0; }
      }
    }

    &.i3 {
      .hero__item {
        &--i1 { width: 0; border: 0; opacity: 0; }
        &--i3 { width: 66%; }
      }
    }
  }
}

@keyframes bumpUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}