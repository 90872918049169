/*
  NOTE

	This file should only contain css related to the grid
	just widths, margins and vertical spacing,
	Any other styling, such as background colours, should be moved to a different
	selector. Nount wrong with 4/5 classes on a single element.
	<div class="g__col gm__1-2 gt__1-3 gd__1-4 cta-box"></div>
	Ideally, if you can, move the cta styling to a child element, leaving the class
	with only grid properties. Oh so reusable, oh so portable
*/

.g {
  width: 100%;
  lost-flex-container: row;

  > * { width: 100%; }
}

.g--ng {
  > * {
    width: 50%;
    flex-basis: 50%;
  }
}

.g__1 {
}

/*
  Footer grid modification
*/
.g--footer {
}

.g--gutter {
  .g__1-2 { lost-column: 1/2 2 gutter(1) flex; }
  .g__1-3 { lost-column: 1/3 3 gutter(1) flex; }
  .g__2-3 { lost-column: 2/3 3 gutter(1) flex; }
}

@include breakpoint(small) {

  .g--gutter {
    .gs__1-2 { lost-column: 1/2 2 gutter(1) flex; }
  }

}

@include breakpoint(medium) {

  .gm__ac { align-items: center; }

  .gm__o1-12 { lost-offset: 1/12 }

  .gm__o-l { lost-offset: clear-left; }

  .gm__o-r { lost-offset: clear-right; }

  .g--gutter {
    .gm__1-2 { lost-column: 1/2 2 gutter(1.5) flex; }

    .gm__1-3 { lost-column: 1/3 3 gutter(1.5) flex; }

    .gm__1-4 { lost-column: 1/4 4 gutter(1.5) flex; }

    .gm__4-10 { lost-column: 4/10 10 gutter(1.5) flex; }
    .gm__6-10 { lost-column: 6/10 10 gutter(1.5) flex; }
  }

  .gm__1-1 { lost-column: 1/1; }

  .gm__1-2 { lost-column: 1/2; }

  .gm__1-3 { lost-column: 1/3; }

  .gm__2-3 { lost-column: 2/3; }

  .gm__6-10 { lost-column: 6/10; }

  .gm__4-10 { lost-column: 4/10; }

  .gm__2-10 { lost-column: 2/10; }

}

@include breakpoint(large) {

  .g--gutter {
    .gl__1-3 { lost-column: 1/3 3 gutter(1.5) flex; }
    .gl__2-3 { lost-column: 2/3 3 gutter(1.5) flex; }

    .gl__1-4 { lost-column: 1/4 4 gutter(1.5) flex; }

    .gl__3-10 { lost-column: 3/10 10 gutter(1.5) flex; }
    .gl__7-10 { lost-column: 7/10 10 gutter(1.5) flex; }
  }

  .gl__1-3 { lost-column: 1/3 0 0 flex; }
}

@include breakpoint(x-large) {

  .g--gutter {
    .gx-l__1-5 { lost-column: 1/5 5 gutter(1.5) flex; }
  }
}

