//=====================================
// #MODELS LIST
//=====================================
.models-list {
  width: 100%;

  .list__box {
    padding-bottom: gutter(2);

    ul {
      padding: 0;
      list-style: none;
      margin-left: 0;

      li {
        padding-bottom: 0;
        margin-bottom: 10px;
      }
    }

    h1 {
      font-size: 8rem;
      line-height: 1;
    }

    a, .a {
      text-decoration: none;
      font-size: 2.5rem;
      display: inline-block;
      line-height: 1.2;
      color: black;
      margin-bottom: 0;
      padding-bottom: 4px;

      &:hover {
        color: palette(brand, compliment)
      }

      &.active--link {
        padding-bottom: 2px;
        border-bottom: 2px solid palette(brand, compliment);
      }
    }
  }

  .list {


    img {
      width: 100%;
    }

    a {
      text-decoration: none;
    }

    ul {
      padding: 0;
      list-style: none;
      margin-left: 0;
    }

    h3, h2 {
      font-size: 2.5rem;
      font-weight: 500;
      padding-top: 10px;
      padding-right: 30px;
    }

    .model-card {
      position: relative;

      .shortlist {
        position: absolute;
        bottom: gutter(.25);
        right: 0;
      }
    }

    .list_image {
      //transform: translate3d(0, 0, 0);
      cursor: pointer;
      position: relative;
      padding-top: 150%;
      //clip-path: inset(0);
      //-webkit-backface-visibility: hidden;
      //transition: clip-path .8s;
      //will-change: clip-path;

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }

      //img { transition: transform .8s; }

      .list_details {
        //transform: translate3d(0, 0, 0);
        //-webkit-backface-visibility: hidden;
        text-align: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(255, 255, 255, .7);
        opacity: 0;
        transition: all .3s;

        .ld_inner {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          font-size: 3rem;
          text-transform: uppercase;

          span {
            display: block;
          }
        }
      }
    }

    a {
      color: black;

      img {
        transition: transform .3s;
      }


      @include breakpoint(medium) {
        &:hover {

          .list_image {
            img {
              transform: scale(.9);
            }

            .list_details {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

@include breakpoint(medium) {
  .models-list {
    width: 100%;
    position: relative;

    .list {
      width: calc(100% - 350px);
    }

    .list__box {
      text-align: right;
      position: absolute;
      right: 0;
      width: 350px;

      h1 {
        text-align: right;
        font-size: 8rem;
      }

      a, .a {
        font-size: 4rem;
        font-family: $heading-font;
      }
    }
  }
}

@include breakpoint(large) {
  .models-list {

    .list {
      width: calc(100% - 400px);

      .list_image .list_details .ld_inner {
        font-size: 2.5rem;
      }
    }

    .list__box {
      width: 400px;

      h1 {
        font-size: 8.5rem;
      }

      a {

        &:hover {
          //.list_image { clip-path: inset(40px); }
        }
      }
    }
  }
}

@include breakpoint(1500px) {
  .models-list .list .list_image .list_details .ld_inner {
    font-size: 3rem;
  }
}

@keyframes bumpUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media(-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
  //.models-list  .list  a :hover  .list_image { clip-path: inset(0) !important; }
}