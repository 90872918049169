@media print {
  .no-print {
    display: none;
  }

  #header, #footer, .link--icon, .shortlist, .cta, .removeModel {
    display: none;
  }

  .sl--page {
    .gs__1-2 {
      width: 50%;
      float: left;
      page-break-inside: avoid;
      display: block;

      &:nth-child(2n+1) {
        clear: left;
      }

      &:nth-child(4n+1) {

      }
    }

    > div > .g { display: block;}
  }
}