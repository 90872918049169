//=====================================
// #BUTTON
//=====================================
.btn {
  border: 0;
  text-decoration: none;
  text-align: center;
  font-size: 2rem;
  line-height: 1.7;
  font-family: $heading-font;
  font-weight: 500;
  color: #333;
  outline: none;
  background-color: palette(brand, compliment);
  padding: 8px 30px;
  border-radius: 25px;
  display: inline-block;
  cursor: pointer;
  transition: all .3s;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover, &:focus {
    color: #fff;
    svg path { fill: white; }
  }

  &--arrow {

    span {
      margin-left: 10px;
      vertical-align: sub;
    }
  }
}

.btn--full { width: 100%; }
.btn--r { float: right; }

//=====================================
// #BUTTON-SIZES
//=====================================
.btn--small {
  font-size: 1.7rem;
  line-height: 2.3rem;
  padding: 7px 20px;
}

.btn--large {
  font-size: 2.8rem;
  line-height: 3.3rem;
  padding: 14px 40px;
}

//=====================================
// #BUTTON-COLORS
//=====================================
.btn--white {
  border-color: white;
  color: white;
}

.btn--white-fill {
  border-color: white;
  background-color: white;
  color: black;

  &:hover {
    color: black;
  }
}

.btn--blue {
  border-color: palette(blue, base);
  color: palette(blue, base);
}

.btn--black {
  border-color: black;
  background-color: black;
  color: white;

  &:hover {
    border-color: palette(blue, base);
    background-color: palette(blue, base);
  }
}