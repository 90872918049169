//=====================================
// #CTA
//=====================================
.cta {
  border: 1px solid palette(brand, compliment);
  padding: gutter(2);
}

@include breakpoint(small) {

}

@include breakpoint(medium) {
  .cta {
    padding: gutter(4);
  }
}

@include breakpoint(large) {
  .cta {
    padding: gutter(6) gutter(4);
  }
}