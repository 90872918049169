// ==========================================================================
// #FOOTER
// ==========================================================================
#footer {
  padding: gutter(1) 0;
  text-align: center;

  .logo {
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 40px;
    transition: background .5s, transform .5s;
  }

  .footer_left {
    a { cursor: pointer; }
    a:hover, a:focus, a:active, a.clicked {
      .logo {
        background-color: palette(brand, compliment);
        transform: scale(1.3);
      }

      .icon path { fill: white; }
    }
  }

  .slash { display: none; }

  .icon_list a {
    margin-right: 10px;
    &:last-of-type { margin-right: 0 }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a { text-decoration: none; }
}

@include breakpoint(medium) {
  #footer {
    position: absolute;
    z-index: 2;
    width: 100%;
    bottom: 0;
    border-top: 3px solid #cccccc;
    padding: gutter(1);

    .footer_left {
      text-align: left;
      float: left;
      display: flex;
      align-items: center;
    }

    .footer_right {
      text-align: left;
      float: right;

      ul {
        display: flex;
        align-items: center;
      }
    }

    .logo {
      margin-bottom: 0;
      margin-right: 15px;
      position: relative;
      display: inline-block;
    }

    ul {
      li {
        margin-right: 15px;
        &:last-of-type { margin-right: 0 }
        float: left;
        padding-bottom: 0;
      }
    }

    p {
      margin-bottom: 0;
    }

    .ql, .slash, a {
      display: inline-block;
    }

    a.link--button {
      border-radius: 25px;
      margin-right: 5px;
      padding: gutter(.5) gutter(1);
      background-color: palette(black, text);
      color: white;
      transition: all .5s;

      &:hover {
        background-color: palette(brand, compliment);
      }
    }

    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }
}

@include breakpoint(large) {
  #footer {
    .logo { margin-right: 30px; }
    ul li { margin-right: 30px; }
  }
}