//=====================================
// #TILE
//=====================================
.tile {
  display: block;
  position: relative;
  height: 300px;
  padding: gutter(1);
  background-color: rgba(255, 255, 255, 0.8);
  margin-bottom: gutter(1);

  .tile_img {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    @include bg-cover();
    opacity: 0.8;
    clip-path: inset(0);
    transition: all .5s;
  }

  .tile_heading {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: white;
    transition: color .5s;
  }

  &:hover {
    .tile_img {
      opacity: .4;
      clip-path: inset(20px);
      transform: scale(.9);
    }

    .tile_heading { color: palette(black, text); }
  }
}

.tile--news {
  text-decoration: none;
  display: block;
  margin-bottom: 60px;

  img {
    clip-path: inset(0);
    transition: all .6s;
  }

  .link--arrow-push {
    transition: color .3s;

    p, span {
      display: inline-block;
      vertical-align: middle;
    }

    span {
      margin-left: 30px;
      margin-bottom: 15px;
    }
  }

  &:hover {
    img {
      opacity: .4;
      clip-path: inset(20px);
      transform: scale(.9);
    }

    .link--arrow-push {
      color: palette(brand, compliment);
    }
  }
}

.tile--sl {
  position: relative;
  margin-bottom: gutter(3);

  img { width: 100%; }

  span {
    display: block;
    text-transform: uppercase;
    font-size: 1.8rem;
    line-height: 1.8;
  }

  .sl__heading {
    font-size: 2.9rem;
    font-weight: 500;
    margin-bottom: 0;
    padding-right: 30px;
  }

  .shortlist {
    position: absolute;
    bottom: gutter(.5);
    right: 33%;
    margin-right: gutter(.25);
  }

  .removeModel {
    p {
      text-decoration: underline;
      color: #333;
      transition: color .3s;
      font-size: 1.8rem;
    }

    &:hover {
      p { color: palette(brand, compliment); }
    }
  }
}

.tile--insta {
  margin-bottom: gutter(1);

  .tile_img {
    opacity: 1;
  }
}

.map {
  border: 0;
  width: 100%;
  height: 350px;
}

.tile--l3 { margin-bottom: 60px; }

.sl--page {

  a { text-decoration: none; }
  a.under { text-decoration: underline; }

  .sl_head-r { margin-bottom: 20px; }

  .nudge {
    margin-left: 40px;
  }
}

@include breakpoint(small) {
  .tile--insta { height: 340px; }

  .sl_head {
    margin-bottom: 25px;
    display: flex;
    align-items: baseline;
    width: 100%;

    h1, .sl_head-r { display: inline-block; }

    .sl_head-r {
      text-align: right;
      float: right;
      flex: 1;

      a {
        display: inline-block;
        vertical-align: bottom;
        margin-bottom: 0;
      }
    }
  }
}

@include breakpoint(medium) {

  .tile--news { margin-bottom: 100px; }
  .tile--l3 { margin-bottom: 0; }
  .map { height: 450px; }

  .tile--insta {
    margin-bottom: gutter(1.5);
    height: 300px;
  }
}

@include breakpoint(large) {
  .tile {
    margin-bottom: 0;
    height: 401px;
    &:hover.tile_img { clip-path: inset(40px); }
  }

  .map { height: 600px; }

  .tile--insta {
    height: 300px;
    margin-bottom: gutter(1.5);
  }
}