//=====================================
// #MODELS HOLD
//=====================================
.slick-list { width: 100%; }

.model-section {
  transition: opacity .3s;
  overflow: hidden;
  width: 100%;
}

.model-hold {
  position: relative;
  width: 100%;

  .prev-page {
    padding: gutter(1);

    a { text-decoration: none }
  }

  .model-info {
    background-color: white;
    padding: gutter(1);
    transition: background .3s;

    .shortlist { margin-bottom: 20px; }
    a {
      text-decoration: none;
      font-size: $p-size;
      line-height: 1.5;
      font-weight: 300;
      text-transform: uppercase;
      display: inline-block;
      margin-bottom: 20px;
      border-bottom: 2px solid #7bd7b7;
      margin-right: 15px;

      &.is-active { color: #7bd7b7; }
    }
  }

  .model-details {

    .md__trigger { display: none; }
    .md__pullout {
      padding: gutter(2) gutter(1);
      background-color: black;
      font-size: 1.8rem;
      color: white;
      margin-bottom: gutter(2);

      span {
        text-transform: uppercase;
        width: 50%;
        float: left;
        padding-bottom: gutter(1);
      }
    }
  }

  .model-imgs {
    outline: 0;
    position: relative;
    width: 100%;
    padding: 0 gutter(1);

    .side--right, .side--left {
      display: flex;
      align-items: center;
    }



    .img-hold {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      img {margin: auto; }
    }
  }

  .model-polaroids {
    opacity: 0;
    height: 0;
    overflow: hidden;
    width: 100%;
    transition: opacity .3s;
    img { margin-bottom: gutter(1); }
  }

  .model-video {
    opacity: 0;
    height: 0;
    overflow: hidden;
    width: 100%;
    transition: opacity .3s;

    .model-vid_hold {
      width: 100%;

      #video {
        width: 100%;
        height: 245px;
      }
    }
  }
}

@include breakpoint(small) {
  .model-hold .model-details .md__pullout {
    padding: gutter(1);
    color: white;
    margin-bottom: gutter(2);

    span {
      width: 33.333%;
      float: left;
      padding-bottom: gutter(1);
    }
  }

  .model-hold {
    .model-video {
      .model-vid_hold {
        #video {
          height: 430px;
        }
      }
    }
  }
}

@include breakpoint(medium) {

  .model-hold {
    min-height: calc(100vh - 105px);

    .prev-page {
      position: absolute;
      top:0;
      right: 0;
      z-index: 99;
      padding: gutter(1) gutter(8) gutter(1) gutter(1);
      a { text-decoration: none }
    }

    .model-info {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      padding: gutter(2) gutter(4) gutter(2) gutter(1);

      .model-title {
        display: flex;
        align-items: center;
        .shortlist { margin-left: gutter(2); }
      }

      a {
        margin-bottom: 0;
        &:last-of-type { margin-right: 0; }
      }
    }

    .model-details {
      position: absolute;
      z-index: 6;
      display: flex;
      bottom: gutter(1);
      left: 0;
      transition: bottom .3s;

      .md__pullout {
        position: relative;
        visibility: hidden;
        transform: translateX(-100%);
        display: inline-block;
        margin-bottom: 0;
        padding: gutter(2) gutter(1);
        transition: all .5s;

        span {
          width: auto;
          float: none;
          padding-bottom: 0;
          margin-right: gutter(1);
        }
      }

      .md__trigger {
        position: relative;
        z-index: 2;
        display: inline-block;
        padding: gutter(1);
        background-color: black;

        svg { transition: transform .3s; }
        path { fill: white; }

        &:after {
          content: "STATS";
          position: absolute;
          left: 100%;
          top: 50%;
          transform: translateY(-50%);
          font-size: 1.8rem;
          padding-left: gutter(1);
        }
      }

      &:hover {
        .md__pullout { visibility: visible; }

        .md__trigger {
          &:after { display: none; }

          svg {
            transform: rotate(45deg);
            path { fill: palette(brand, compliment); }
          }

          & ~ .md__pullout { transform: translateX(0); }
        }
      }
    }

    .model-imgs {
      height: calc(100vh - 105px);

      .side--right {
        padding-top: gutter(4);
      }

      .side--left { display: inline-block; }

      .side, .full {
        height: calc(100vh - 105px);

        img {
          padding: gutter(1) 0;
          max-height: 100%;

          &.img-first {
            padding-top: 200px;
            padding-bottom: 150px;
          }
        }
      }
      .full img { padding: gutter(5) 0 gutter(1);}
    }

    .slick-slide {
      .animated {
        opacity: 0;
        -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0 * 100);
        filter: alpha(opacity=0 * 100);
      }
    }

    .model-polaroids {

      img { margin-bottom: gutter(1.5); }

      > div {
        padding-top: 240px;
        padding-bottom: 96px;
      }
    }

    .arrow {
      bottom: 50%;
      padding: gutter(1.5) gutter(.5);
    }

    .model-video {
      .model-vid_hold {
        #video {
          min-height: calc(100vh - 100px);
        }
      }
    }

    &.video-true {

      .model-info {
        background-color: white;
      }

      .model-details {
        bottom: gutter(4);

        .md__trigger:after {
          color: white;
        }
      }
    }
  }
}

@include breakpoint(large) {

  .model-hold {

    .prev-page {
      padding: gutter(1) gutter(7) gutter(1) gutter(1);
    }

    .model-details .md__pullout {
      span { margin-right: gutter(2); }
    }
  }


}