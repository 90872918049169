//=====================================
// #FORM
//=====================================
input {
  border: solid 1px black;
  border-radius: 0px;
  font-size: 1.5rem;
  line-height: 1.5;
  padding: 15px;
  outline: none;
  -webkit-appearance: none; /* for box shadows to show on iOS */
  transition: background .3s, border .5s;

  &:focus {
    border-color: #a1a1a1;
    background-color: #fbfbfb;
  }

  &:not(:placeholder-shown) {
    background-color: #fbfbfb;
  }

  &[disabled] {
    background-color: #f7f7f8;
    cursor: not-allowed;
  }
}

input[type=checkbox] {
  -webkit-appearance: checkbox;
  margin-right: 5px;
}

.input--hold {
  margin-bottom: 20px;
}

.input--full {
  width: 100%;
}

textarea {
  border: solid 1px black;
  border-radius: 0;
  font-size: 1.5rem;
  line-height: 1.5;
  padding: 15px;
  outline: none;
  transition: background .3s, border .5s;

  &:focus {
    border-color: #a1a1a1;
    background-color: #fbfbfb;
  }

  &:not(:empty) {
    background-color: #fbfbfb;
  }
}

select {
  border: solid 1px black;
  border-radius: 0;
  font-size: 1.5rem;
  line-height: 1.5;
  padding: 15px 40px 15px 15px;
  outline: none;
  -webkit-appearance: none; /* for box shadows to show on iOS */
}

//=====================================
// #FORM-TYPES
//=====================================

//=====================================
// #FORM-COLORS
//=====================================
.input--success {
  border-color: palette(green);
}

.input--error {
  border-color: palette(red);
}

