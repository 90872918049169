//=====================================
// #HELPERS
//=====================================
.pb {
    margin-bottom: 20px;
}

.bumper {
    margin-bottom: 40px;
}

.bumper--2 {
    margin-bottom: 60px;
}

.tt-u {
    text-transform: uppercase;
}

.bold, strong {
    font-weight: 800;
}

.normal {
    font-weight: 400;
}

em, .italic {
    font-style: italic;
}

.under {
    text-decoration: underline;
}

.ta-c {
    text-align: center;
    > * {
        text-align: center;
    }
}

.ta-l {
    text-align: left;
}

.ta-r {
    text-align: right;
}

.td-n {
    text-decoration: none;
}

.ta-r--md {
    @include breakpoint(medium) {
        text-align: right;
    }
}

.right--md {
    @include breakpoint(medium) {
        float: right;
    }
}

.img--responsive {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.sr-text {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
    position: absolute !important;
    white-space: nowrap;
    height: 1px;
    width: 1px;
    overflow: hidden;
}

.frame {
    width: 100%;
    height: 250px;
    @include breakpoint(small) { height: 395px; }
    @include breakpoint(medium) { height: 520px; }
    @include breakpoint(large) { height: 425px; }
}

.mb-s {
    margin-bottom: 20px;
}

.spacer-fixed-top {
    margin-top: 20px;
}

.spacer-fixed {
    margin-bottom: 20px;
}

.nudge {
    margin-right: 10px;
}

.spacer {
    margin-bottom: 8vh;

    &--large {
        margin-bottom: 8vh;

        @include breakpoint(small) {
            margin-bottom: 15vh;
        }
    }

    &-top {
        margin-top: 8vh;

        &--large {
            margin-top: 8vh;

            @include breakpoint(small) {
                margin-top: 15vh;
            }
        }
    }
}

.a11y {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
    position: absolute !important;
    white-space: nowrap;
    height: 1px;
    width: 1px;
    overflow: hidden;
}